import React from "react"
import ReactDOM from "react-dom"
import { AppContainer } from "react-hot-loader"
import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import App from "./containers/App"
import rootReducer from "./reducers"
import { routerMiddleware } from "react-router-redux"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import * as ServiceWorker from "./serviceWorker"
import { Router } from "react-router"

const history = createBrowserHistory();

function configureStore() {
  // Redux Configuration
  const middleware = [];
  const enhancers = [];

  // Logging Middleware
  const logger = createLogger({
    level: "info",
    collapsed: true
  });
  middleware.push(logger);

  // Router Middleware
  const router = routerMiddleware(history);
  middleware.push(router);

  // Redux DevTools Configuration
  const actionCreators = {};
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Options: http://zalmoxisus.github.io/redux-devtools-extension/API/Arguments.html
        actionCreators
      })
    : compose;
  /* eslint-enable no-underscore-dangle */

  // Apply Middleware & Compose Enhancers
  enhancers.push(applyMiddleware(...middleware));
  const enhancer = composeEnhancers(...enhancers);

  // Create Store
  const store = createStore(rootReducer, enhancer);

  if (module.hot) {
    module.hot.accept(
      "./reducers",
      () => store.replaceReducer(require("./reducers")) // eslint-disable-line global-require
    );
  }

  return store;
}

// when running dev serveer 'npm run dev'

const store = configureStore();

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Router history={history} basename={window.location.pathname || ""}>
          <App history={history} basename={window.location.pathname || ""} />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

render(App);

// when running 'npm run pro'

global.NhogsWebsite = global.NhogsWebsite || {
  initApp: config => {
    const store = configureStore();

    const render = Component => {
      ReactDOM.render(
        <AppContainer>
          <Provider store={store}>
            <Router history={history} basename={window.location.pathname || ""}>
              <App
                history={history}
                basename={window.location.pathname || ""}
              />
            </Router>
          </Provider>
        </AppContainer>,
        document.getElementById("root")
      );
    };

    render(App);
  }
};

ServiceWorker.unregister();
