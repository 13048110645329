export const nhogsLogo = [
  {
    d:
      "m 93.806514,236.04671 c 1.653409,0.84745 7.701196,0.9347 11.337786,0.0886 0.80217,-0.18662 -2.08449,-1.14269 -2.45463,-1.26607 -3.688787,-0.95422 -6.478607,-0.12036 -8.883156,1.17743 z",
    x: 99.54548263549805,
    y: 235.5926284790039
  },
  {
    d:
      "m 90.267691,234.90571 c 0,0 -0.350389,-0.24108 -0.662608,-0.35154 -2.80276,-0.99157 -11.123902,-2.78253 -5.918903,0.48383 1.215082,0.76252 2.88195,0.85414 3.806967,0.81466 2.20856,-0.0942 2.960785,-0.90817 2.774544,-0.94695 z",
    x: 86.15227508544922,
    y: 234.51215362548828
  },
  {
    d:
      "m 109.30374,234.9057 c 0,0 0.35039,-0.24108 0.66261,-0.35154 2.80276,-0.99157 11.1239,-2.78253 5.9189,0.48383 -1.21508,0.76252 -2.88195,0.85414 -3.80696,0.81466 -2.20856,-0.0942 -2.96079,-0.90817 -2.77455,-0.94695 z",
    x: 113.4191665649414,
    y: 234.51213836669922
  },
  {
    d:
      "m 84.775716,231.00873 c 1.299651,-1.40071 2.798409,-1.92189 4.473316,-1.87498 4.284115,0.11997 9.182421,3.40559 9.195101,3.60614 0.0456,0.72058 -8.596206,3.43537 -13.668417,-1.73116 z",
    x: 91.61001968383789,
    y: 231.6137924194336
  },
  {
    d:
      "m 114.79572,231.00872 c -1.29965,-1.40071 -2.79841,-1.92189 -4.47332,-1.87498 -4.28411,0.11997 -9.18242,3.40559 -9.1951,3.60614 -0.0456,0.72058 8.59621,3.43537 13.66842,-1.73116 z",
    x: 107.96142196655273,
    y: 231.61377716064453
  },
  {
    d:
      "m 117.93844,232.11274 c -0.0479,-0.10099 -0.10363,-0.1968 0.0374,-0.33327 1.37346,-1.3289 11.94597,-5.99976 11.42069,-4.41494 -0.59555,1.79685 -9.07101,5.95872 -9.68184,5.89329 -0.58132,-0.0623 -1.47417,-0.50869 -1.77626,-1.14508 z",
    x: 123.65078735351562,
    y: 230.14946746826172
  },
  {
    d:
      "m 81.632994,232.11275 c 0.04794,-0.10099 0.103632,-0.1968 -0.03741,-0.33327 -1.373457,-1.3289 -11.945971,-5.99976 -11.420692,-4.41494 0.595555,1.79685 9.071015,5.95872 9.681838,5.89329 0.581326,-0.0623 1.474174,-0.50869 1.776267,-1.14508 z",
    x: 75.92064666748047,
    y: 230.14946746826172
  },
  {
    d:
      "m 90.468194,226.17343 c 0,0 3.772382,-4.23747 8.784999,-5.01262 4.789397,0.34093 9.719177,5.04418 9.482617,5.45187 -0.23356,-0.0629 -3.05088,2.99839 -6.9016,4.06931 -1.32917,0.36965 -2.652937,0.35921 -3.959067,0.0386 -2.25211,-0.55276 -4.788922,-2.19018 -7.406949,-4.54718 z",
    x: 99.60609436035156,
    y: 226.06053161621094
  },
  {
    d:
      "m 87.445124,227.71081 c 0.987571,-0.20619 -15.325839,-11.78094 -17.053222,-6.73086 -1.106149,3.23388 2.355845,4.89627 5.797373,6.38862 2.583919,1.12046 4.773187,2.65163 6.592917,3.34391 1.614566,0.61423 0.682517,-2.17064 4.662932,-3.00167 z",
    x: 78.83509826660156,
    y: 225.2528533935547
  },
  {
    d:
      "m 112.12631,227.7108 c -0.98757,-0.20619 15.32584,-11.78094 17.05322,-6.73086 1.10615,3.23388 -2.35584,4.89627 -5.79737,6.38862 -2.58392,1.12046 -4.77319,2.65163 -6.59292,3.34391 -1.61456,0.61423 -0.68252,-2.17064 -4.66293,-3.00167 z",
    x: 120.7363395690918,
    y: 225.25283813476562
  },
  {
    d:
      "m 70.432135,226.13378 c 0.131546,-0.009 -0.306473,-1.27194 -1.924167,-2.38864 -3.37596,-2.33042 -8.924928,-5.73345 -11.159497,-6.18583 -1.22376,-0.24775 -1.649931,0.52044 0.276251,2.52974 9.184898,9.58127 12.660334,6.0549 12.807413,6.04473",
    x: 63.37938690185547,
    y: 222.14515686035156
  },
  {
    d:
      "m 129.1393,226.13377 c -0.13155,-0.009 0.30647,-1.27194 1.92416,-2.38864 3.37597,-2.33042 8.92493,-5.73345 11.1595,-6.18583 1.22376,-0.24775 1.64993,0.52044 -0.27625,2.52974 -9.1849,9.58127 -12.66033,6.0549 -12.80741,6.04473",
    x: 136.19204711914062,
    y: 222.14515686035156
  },
  {
    d:
      "m 75.637002,218.30529 c 0,0 -3.371949,-4.20329 5.973596,-9.48303 2.493494,-1.40869 8.824411,3.25258 13.276824,6.71066 2.323771,1.80482 2.918561,4.15266 2.856741,4.30709 -0.19497,0.48705 -1.699,-0.33398 -7.344697,4.20218 -0.37473,0.30109 -1.023732,0.17387 -1.663621,0.22469 -0.622264,0.0494 -1.242833,0.32457 -1.714669,0.20346 -1.891491,-0.48549 -3.909429,-2.69802 -5.908761,-3.62938 -3.280055,-1.52796 -5.475413,-2.53567 -5.475413,-2.53567 z",
    x: 86.3965072631836,
    y: 216.53257751464844
  },
  {
    d:
      "m 123.93443,218.30528 c 0,0 3.37195,-4.20329 -5.97359,-9.48303 -2.4935,-1.40869 -8.82442,3.25258 -13.27683,6.71066 -2.32377,1.80482 -2.91856,4.15266 -2.85674,4.30709 0.19497,0.48705 1.699,-0.33398 7.3447,4.20218 0.37473,0.30109 1.02373,0.17387 1.66362,0.22469 0.62226,0.0494 1.24283,0.32457 1.71467,0.20346 1.89149,-0.48549 3.90943,-2.69802 5.90876,-3.62938 3.28005,-1.52796 5.47541,-2.53567 5.47541,-2.53567 z",
    x: 113.1749267578125,
    y: 216.53257751464844
  },
  {
    d:
      "m 68.968732,222.60259 c 0,0 -6.217072,-2.93023 -10.761438,-7.13793 -3.380511,-3.13007 -3.426257,-3.43139 -3.772472,-8.07579 -0.11411,-1.53076 0.265839,-1.62566 1.324132,-1.42552 3.938304,0.7448 11.605646,6.90501 15.337454,10.21904 1.405326,1.24799 2.232367,2.0981 2.039512,2.15379 -0.212236,0.0613 -0.64641,0.14291 -0.796093,0.16783 -1.595103,0.26566 -3.161004,1.73564 -3.315252,3.07967 -0.02971,0.25892 0.006,0.73889 -0.05584,1.01891 z",
    x: 63.78949546813965,
    y: 214.2442626953125
  },
  {
    d:
      "m 130.6027,222.60258 c 0,0 6.21707,-2.93023 10.76144,-7.13793 3.38051,-3.13007 3.42626,-3.43139 3.77247,-8.07579 0.11411,-1.53076 -0.26584,-1.62566 -1.32413,-1.42552 -3.93831,0.7448 -11.60565,6.90501 -15.33745,10.21904 -1.40533,1.24799 -2.23237,2.0981 -2.03952,2.15379 0.21224,0.0613 0.64641,0.14291 0.7961,0.16783 1.5951,0.26566 3.161,1.73564 3.31525,3.07967 0.0297,0.25892 -0.006,0.73889 0.0558,1.01891 z",
    x: 135.78195571899414,
    y: 214.2442626953125
  },
  {
    d:
      "m 55.835023,214.66204 c -2.029988,-5.7096 -15.049586,-13.42839 -6.910469,-2.97135 1.252703,1.60945 4.674706,4.03086 5.59227,3.92967 0.907405,-0.10007 1.61093,-0.13498 1.318199,-0.95832 z",
    x: 51.0884895324707,
    y: 211.07275390625
  },
  {
    d:
      "m 143.73641,214.66203 c 2.02999,-5.7096 15.04959,-13.42839 6.91047,-2.97135 -1.2527,1.60945 -4.67471,4.03086 -5.59227,3.92967 -0.90741,-0.10007 -1.61093,-0.13498 -1.3182,-0.95832 z",
    x: 148.48294067382812,
    y: 211.07275390625
  },
  {
    d:
      "m 97.591593,215.59208 c -0.70618,0.0172 -7.415421,-6.40724 -11.194013,-7.99323 -3.619827,-1.51936 7.07377,-14.383 13.118133,-14.06819 6.065127,0.31589 16.975097,11.00076 13.384537,14.48512 -1.94593,1.88837 -4.70666,2.32407 -6.17806,3.34709 -2.92301,2.03229 -1.35901,1.41618 -4.79112,4.15835 -0.57448,0.2248 -1.17653,-0.21361 -2.241147,-0.22566 -0.22223,0.005 -0.77037,0.26413 -2.09833,0.29652 z",
    x: 99.6380386352539,
    y: 204.55855560302734
  },
  {
    d:
      "m 58.330377,201.5861 c -0.02822,-0.56143 -0.220448,-8.86655 4.82524,-9.51461 7.180263,0.6799 20.619905,14.27716 19.6923,14.93625 -2.036952,1.44731 -3.677151,2.06855 -4.698347,2.86377 -2.482192,1.93291 -2.513046,2.94583 -4.448552,5.20788 -0.133042,0.15549 -0.466023,0.53208 -0.59154,0.39158 -5.343534,-5.98148 -8.011918,-8.15511 -13.274501,-11.18861 -1.183598,-0.68226 -1.46066,-1.82195 -1.5046,-2.69626 z",
    x: 70.60792541503906,
    y: 203.78665924072266
  },
  {
    d:
      "m 141.24106,201.58609 c 0.0282,-0.56143 0.22044,-8.86655 -4.82524,-9.51461 -7.18027,0.6799 -20.61991,14.27716 -19.6923,14.93625 2.03695,1.44731 3.67715,2.06855 4.69834,2.86377 2.4822,1.93291 2.51305,2.94583 4.44856,5.20788 0.13304,0.15549 0.46602,0.53208 0.59154,0.39158 5.34353,-5.98148 8.01191,-8.15511 13.2745,-11.18861 1.18359,-0.68226 1.46066,-1.82195 1.5046,-2.69626 z",
    x: 128.96349334716797,
    y: 203.78662872314453
  },
  {
    d:
      "m 52.93477,208.19405 c 0,0 -4.880748,-3.51821 -9.425114,-7.72591 -3.380511,-3.13007 -3.158992,-4.76772 -3.505207,-9.41212 -0.11411,-1.53076 0.265839,-1.62566 1.324132,-1.42552 3.938304,0.7448 10.002057,8.82932 13.733865,12.14335 1.405326,1.24799 2.232367,2.0981 2.039512,2.15379 -0.212236,0.0613 -0.64641,0.14291 -0.796093,0.16783 -1.595103,0.26566 -3.161004,1.73564 -3.315252,3.07967 -0.02971,0.25892 0.273262,0.96607 0.211422,1.24609 z",
    x: 48.55732345581055,
    y: 198.9871597290039
  },
  {
    d:
      "m 146.63666,208.19404 c 0,0 4.88075,-3.51821 9.42512,-7.72591 3.38051,-3.13007 3.15899,-4.76772 3.5052,-9.41212 0.11411,-1.53076 -0.26583,-1.62566 -1.32413,-1.42552 -3.9383,0.7448 -10.00206,8.82932 -13.73386,12.14335 -1.40533,1.24799 -2.23237,2.0981 -2.03952,2.15379 0.21224,0.0613 0.64641,0.14291 0.7961,0.16783 1.5951,0.26566 3.161,1.73564 3.31525,3.07967 0.0297,0.25892 -0.27326,0.96607 -0.21142,1.24609 z",
    x: 151.01410675048828,
    y: 198.9871597290039
  },
  {
    d:
      "m 66.888602,188.81828 c -0.0233,-6.40484 6.895803,-14.26011 10.965298,-14.51825 4.385096,-0.27815 10.244449,3.92554 14.550103,9.68364 2.048733,2.73984 5.11795,6.71516 3.69874,8.30939 -4.862422,2.5589 -8.981613,7.15834 -11.581311,9.51882 -0.460458,0.41053 -0.560529,-0.091 -1.914716,0.041 -0.221237,0.0216 -0.299081,0.0161 -1.44514,0.28825 -2.721329,-4.84816 -13.996839,-9.48777 -14.272974,-13.32285 z",
    x: 81.67678833007812,
    y: 188.2140655517578
  },
  {
    d:
      "m 132.68283,188.81827 c 0.0233,-6.40484 -6.8958,-14.26011 -10.9653,-14.51825 -4.38509,-0.27815 -10.24445,3.92554 -14.5501,9.68364 -2.04873,2.73984 -5.11795,6.71516 -3.69874,8.30939 4.86242,2.5589 8.98161,7.15834 11.58131,9.51882 0.46046,0.41053 0.56053,-0.091 1.91472,0.041 0.22123,0.0216 0.29908,0.0161 1.44514,0.28825 2.72133,-4.84816 13.99684,-9.48777 14.27297,-13.32285 z",
    x: 117.8946418762207,
    y: 188.21405029296875
  },
  {
    d:
      "m 44.979164,172.22073 c 5.91038,-1.78824 20.704377,15.17281 18.787858,17.68895 -7.005139,2.16014 -5.816078,6.34572 -6.888712,10.38041 -7.875322,-9.76831 -18.58378,-15.33887 -11.899146,-28.06936 z",
    x: 53.407230377197266,
    y: 186.18953704833984
  },
  {
    d:
      "m 154.59227,172.22072 c -5.91038,-1.78824 -20.70438,15.17281 -18.78786,17.68895 7.00514,2.16014 5.81608,6.34572 6.88871,10.38041 7.87532,-9.76831 18.58378,-15.33887 11.89915,-28.06936 z",
    x: 146.1642074584961,
    y: 186.18952178955078
  },
  {
    d:
      "m 42.213816,187.80769 c 0.123316,0.0467 0.478614,-1.32168 -0.526069,-3.01123 -2.096676,-3.52589 -5.796748,-9.23518 -7.63913,-10.57815 -1.008979,-0.73548 -1.640676,0.057 -0.954983,2.75467 3.324631,13.07975 8.097773,11.24814 9.120182,10.83471",
    x: 37.59141540527344,
    y: 181.04458618164062
  },
  {
    d:
      "m 157.35762,187.80768 c -0.12332,0.0467 -0.47862,-1.32168 0.52607,-3.01123 2.09667,-3.52589 5.79674,-9.23518 7.63913,-10.57815 1.00897,-0.73548 1.64067,0.057 0.95498,2.75467 -3.32463,13.07975 -8.09777,11.24814 -9.12018,10.83471",
    x: 161.98001861572266,
    y: 181.04457092285156
  },
  {
    d:
      "m 84.012885,173.84504 c -4.529189,-3.1966 9.302902,-21.51546 16.714455,-20.5177 4.99701,0.97531 9.71789,6.45837 13.5957,12.61644 0.97497,2.02075 3.32961,6.19359 0.94246,7.81724 -7.08828,4.82118 -12.66375,10.86035 -15.395537,14.05303 -0.13304,0.15549 -0.46952,0.53513 -0.59154,0.39158 -6.507767,-7.6561 -9.837158,-11.05291 -15.265538,-14.36059 z",
    x: 99.71133041381836,
    y: 170.7627716064453
  },
  {
    d:
      "m 148.02522,168.42265 c 0.0282,-0.56143 -4.42734,-14.29927 -9.47303,-14.94733 -7.50669,0.13584 -19.26915,16.67009 -17.13249,18.32382 5.34612,1.33217 10.17909,8.37323 12.17122,12.54545 0.13304,0.15549 2.02366,-0.0489 2.14918,-0.18945 3.24152,-8.4686 11.56715,-9.57764 12.28512,-15.73249 z",
    x: 134.59488677978516,
    y: 168.93785095214844
  },
  {
    d:
      "m 51.546212,168.42266 c -0.02822,-0.56143 4.427342,-14.29927 9.47303,-14.94733 7.506696,0.13584 19.269148,16.67009 17.132496,18.32382 -5.346127,1.33217 -10.179095,8.37323 -12.171225,12.54545 -0.133043,0.15549 -2.023662,-0.0489 -2.149179,-0.18945 -3.24152,-8.4686 -11.567151,-9.57764 -12.285122,-15.73249 z",
    x: 64.9765453338623,
    y: 168.93785095214844
  },
  {
    d:
      "m 33.354508,149.78594 c 5.319868,-1.5914 16.305824,18.02489 15.008161,20.33474 -6.389154,-2.65303 -6.971279,3.7129 -7.41787,8.56616 -7.875322,-9.76831 -11.495369,-15.63588 -7.590291,-28.9009 z",
    x: 40.120174407958984,
    y: 164.1905288696289
  },
  {
    d:
      "m 166.21692,149.78593 c -5.31986,-1.5914 -16.30582,18.02489 -15.00816,20.33474 6.38916,-2.65303 6.97128,3.7129 7.41787,8.56616 7.87533,-9.76831 11.49537,-15.63588 7.59029,-28.9009 z",
    x: 159.45125579833984,
    y: 164.1905288696289
  },
  {
    d:
      "m 66.314527,152.32223 c -4.52919,-3.1966 7.43674,-21.65699 14.822373,-20.48261 5.028132,0.79952 9.133927,5.93025 12.847358,12.18882 1.144885,1.92957 3.529275,6.93305 1.055855,8.42199 -7.945824,4.7832 -11.294856,12.44711 -14.212541,15.54596 -0.14028,0.149 -0.46952,0.53513 -0.59154,0.39158 -6.50777,-7.6561 -8.493125,-12.75806 -13.921505,-16.06574 z",
    x: 80.72031021118164,
    y: 150.10269927978516
  },
  {
    d:
      "m 133.25691,152.32222 c 4.52919,-3.1966 -7.43674,-21.65699 -14.82238,-20.48261 -5.02813,0.79952 -9.13392,5.93025 -12.84735,12.18882 -1.14489,1.92957 -3.52928,6.93305 -1.05586,8.42199 7.94582,4.7832 11.29486,12.44711 14.21254,15.54596 0.14028,0.149 0.46952,0.53513 0.59154,0.39158 6.50777,-7.6561 8.49313,-12.75806 13.92151,-16.06574 z",
    x: 118.85112762451172,
    y: 150.10269165039062
  },
  {
    d:
      "m 47.158893,131.34464 c 7.506696,0.13584 16.953142,17.66697 14.816489,19.3207 -6.544939,1.65538 -9.696724,10.32939 -11.026924,13.89553 -0.133043,0.15549 -0.466024,0.53208 -0.591541,0.39158 -5.458167,-4.69503 -15.652668,-22.27262 -3.198024,-33.60781 z",
    x: 51.756683349609375,
    y: 148.16394805908203
  },
  {
    d:
      "m 152.41254,131.34463 c -7.5067,0.13584 -16.95314,17.66697 -14.81649,19.3207 6.54494,1.65538 9.69672,10.32939 11.02692,13.89553 0.13305,0.15549 0.46603,0.53208 0.59155,0.39158 5.45816,-4.69503 15.65266,-22.27262 3.19802,-33.60781 z",
    x: 147.81473541259766,
    y: 148.16394805908203
  },
  {
    d:
      "m 38.259409,149.22522 c 0.09209,0.0944 1.105036,-1.56323 0.907155,-3.51894 -0.412971,-4.08135 -2.000767,-11.31013 -3.104348,-13.30514 -0.18734,-0.33866 -0.517158,-0.79351 -0.74203,-0.81339 -0.500589,-0.0442 -0.929635,1.02051 -1.286194,2.90779 -2.505385,13.26107 3.124037,14.67306 4.225417,14.72968",
    x: 36.29502487182617,
    y: 140.40774536132812
  },
  {
    d:
      "m 161.31202,149.22521 c -0.0921,0.0944 -1.10503,-1.56323 -0.90715,-3.51894 0.41297,-4.08135 2.00077,-11.31013 3.10435,-13.30514 0.18734,-0.33866 0.51715,-0.79351 0.74203,-0.81339 0.50059,-0.0442 0.92963,1.02051 1.28619,2.90779 2.50539,13.26107 -3.12404,14.67306 -4.22542,14.72968",
    x: 163.27642822265625,
    y: 140.40773010253906
  },
  {
    d:
      "m 85.165551,130.86545 c -4.529189,-3.1966 7.899817,-20.97813 15.311369,-19.98037 4.99701,0.97531 9.72249,7.96209 13.6003,14.12015 0.97497,2.02075 2.38469,4.37934 -0.002,6.00299 -7.08828,4.82118 -11.04851,12.91674 -13.7803,16.10942 -0.13304,0.15549 -0.469517,0.53513 -0.591537,0.39158 -6.507773,-7.65609 -9.108992,-13.33609 -14.537372,-16.64377 z",
    x: 99.79286193847656,
    y: 129.1928482055664
  },
  {
    d:
      "m 54.733501,131.73992 c -4.52919,-3.1966 5.07001,-20.16411 12.264581,-19.21551 5.037795,0.66423 5.31643,3.36006 9.748003,9.24066 1.350312,1.79183 3.378093,6.55508 0.904673,8.04402 -7.945827,4.7832 -10.390245,11.64321 -12.942522,15.10747 -0.121385,0.16476 -0.493023,0.55217 -0.59154,0.39158 -5.301926,-8.6427 -4.718925,-8.68805 -9.383195,-13.56822 z",
    x: 66.19038772583008,
    y: 128.9162254333496
  },
  {
    d:
      "m 144.83793,131.73991 c 4.52919,-3.1966 -5.07001,-20.16411 -12.26458,-19.21551 -5.03779,0.66423 -5.31643,3.36006 -9.748,9.24066 -1.35031,1.79183 -3.37809,6.55508 -0.90467,8.04402 7.94582,4.7832 10.39024,11.64321 12.94252,15.10747 0.12138,0.16476 0.49302,0.55217 0.59154,0.39158 5.30192,-8.6427 4.71892,-8.68805 9.38319,-13.56822 z",
    x: 133.38105392456055,
    y: 128.9162254333496
  },
  {
    d:
      "m 41.949907,109.70492 c 6.614613,1.32528 10.373505,19.02059 8.470376,20.36295 -2.282636,0.57734 -3.213372,-0.99033 -4.377933,-0.23801 -2.174553,1.40487 -3.518676,5.28829 -4.384951,7.61069 -0.133043,0.15549 -0.466024,0.53208 -0.591541,0.39158 -1.031654,-1.95011 -3.26036,-4.93285 -3.93652,-9.15254 -1.23598,-7.71335 1.030585,-17.46171 4.820569,-18.97467 z",
    x: 43.85235786437988,
    y: 123.78393936157227
  },
  {
    d:
      "m 157.62153,109.70491 c -6.61462,1.32528 -10.37351,19.02059 -8.47038,20.36295 2.28264,0.57734 3.21337,-0.99033 4.37793,-0.23801 2.17456,1.40487 3.51868,5.28829 4.38495,7.61069 0.13305,0.15549 0.46603,0.53208 0.59155,0.39158 1.03165,-1.95011 3.26036,-4.93285 3.93651,-9.15254 1.23598,-7.71335 -1.03058,-17.46171 -4.82056,-18.97467 z",
    x: 155.71907806396484,
    y: 123.78392791748047
  },
  {
    d:
      "m 67.236556,111.43028 c -0.08266,-7.70994 -4.925816,-19.819584 -10.256103,-18.266893 -5.80168,6.868383 -12.141846,18.027943 -3.921307,28.932993 0.163751,0.12273 0.494161,0.55286 0.591541,0.39158 6.26931,-10.38304 8.018879,-11.69054 13.585869,-11.05768 z",
    x: 58.183305740356445,
    y: 107.77560043334961
  },
  {
    d:
      "m 132.33488,111.43027 c 0.0827,-7.70994 4.92581,-19.819578 10.2561,-18.266887 5.80168,6.868377 12.14185,18.027937 3.92131,28.932987 -0.16375,0.12273 -0.49416,0.55286 -0.59154,0.39158 -6.26931,-10.38304 -8.01888,-11.69054 -13.58587,-11.05768 z",
    x: 141.3881378173828,
    y: 107.77558517456055
  },
  {
    d:
      "m 71.394571,111.21326 c -4.384854,-3.10639 6.132969,-21.15261 10.65067,-21.963506 4.314649,2.340032 9.388543,8.777393 13.396772,14.285886 1.3201,1.8142 2.06397,4.05862 -0.32272,5.68227 -7.088278,4.82118 -10.136251,11.39329 -12.868041,14.58597 -0.13304,0.15549 -0.46952,0.53513 -0.59154,0.39158 -6.50767,-7.65609 -6.225984,-8.49855 -10.264581,-12.9822 z",
    x: 83.51119613647461,
    y: 106.73851776123047
  },
  {
    d:
      "m 128.17686,111.21325 c 4.38486,-3.10639 -6.13297,-21.152604 -10.65067,-21.9635 -4.31465,2.340032 -9.38854,8.777393 -13.39677,14.28588 -1.3201,1.8142 -2.06397,4.05862 0.32272,5.68227 7.08828,4.82118 10.13625,11.39329 12.86804,14.58597 0.13304,0.15549 0.46952,0.53513 0.59154,0.39158 6.50767,-7.65609 6.22599,-8.49855 10.26458,-12.9822 z",
    x: 116.06024551391602,
    y: 106.73851013183594
  },
  {
    d:
      "m 46.850839,111.5841 c 0.04664,0.12336 0.757955,-1.98777 1.358865,-3.85936 1.254018,-3.90582 0.871213,-12.370373 0.657754,-14.640263 -0.03624,-0.385319 -0.156586,-0.934123 -0.354734,-1.042293 -2.811395,1.113893 -7.349247,13.198496 -6.674646,15.560516 2.473791,1.08433 4.942715,3.3072 5.012761,3.9814",
    x: 45.43224906921387,
    y: 101.81571960449219
  },
  {
    d:
      "m 152.72059,111.58409 c -0.0466,0.12336 -0.75795,-1.98777 -1.35886,-3.85936 -1.25402,-3.90582 -0.87121,-12.370367 -0.65775,-14.640257 0.0362,-0.385319 0.15658,-0.934123 0.35473,-1.042293 2.81139,1.113893 7.34925,13.19849 6.67465,15.56051 -2.4738,1.08433 -4.94272,3.3072 -5.01277,3.9814",
    x: 154.13919830322266,
    y: 101.81571578979492
  },
  {
    d:
      "m 99.452733,76.004922 c 2.714787,1.856045 12.337727,9.3614 12.098597,12.416071 -0.7345,3.899999 -6.07795,10.090224 -12.114977,15.976797 -1.35023,-1.85884 -11.593646,-11.380052 -11.925125,-14.29172 0.562112,-3.480069 3.94818,-10.881707 11.941505,-14.101148 z",
    x: 99.5334701538086,
    y: 90.20135498046875
  },
  {
    d:
      "m 70.597703,72.657725 c 4.31465,2.34003 6.48546,5.186522 10.71809,10.524521 1.21341,1.530301 2.494,4.123387 0.37217,4.345945 -4.25758,0.446576 -9.66798,10.587576 -12.28006,16.296469 -0.0851,0.18609 -0.46952,0.53513 -0.59154,0.39158 -5.30902,-8.245613 -10.38948,-27.131704 1.78134,-31.558515 z",
    x: 72.95960235595703,
    y: 88.4538688659668
  },
  {
    d:
      "m 128.97373,72.657721 c -4.31465,2.34003 -6.48546,5.186522 -10.71809,10.524521 -1.21341,1.530301 -2.494,4.123387 -0.37217,4.345945 4.25758,0.446576 9.66798,10.587576 12.28006,16.296463 0.0851,0.18609 0.46952,0.53513 0.59154,0.39158 5.30902,-8.245607 10.38948,-27.131698 -1.78134,-31.558509 z",
    x: 126.61181640625,
    y: 88.45386505126953
  },
  {
    d:
      "m 60.831027,92.220091 c 0.435,-1.102642 -1.367545,-15.156925 -2.361614,-14.912937 -3.577724,0.43238 -8.912568,13.213415 -7.979518,19.918953 0.3096,2.225 3.415221,-5.092724 6.118576,-5.655412 1.218793,-0.253685 3.571906,0.476586 3.910856,0.967486 0.11544,0.16719 0.24699,-0.11319 0.3117,-0.31809",
    x: 55.639862060546875,
    y: 87.4749526977539
  },
  {
    d:
      "m 138.74041,92.220087 c -0.435,-1.102642 1.36754,-15.156925 2.36161,-14.912937 3.57772,0.43238 8.91257,13.213415 7.97952,19.918953 -0.3096,2.225 -3.41522,-5.092724 -6.11858,-5.655412 -1.21879,-0.253685 -3.5719,0.476586 -3.91085,0.967486 -0.11544,0.16719 -0.24699,-0.11319 -0.3117,-0.31809",
    x: 143.93157958984375,
    y: 87.47493743896484
  },
  {
    d:
      "m 88.644915,61.924147 c 0.483973,4.252962 1.871982,7.18702 7.133168,11.89263 0.69076,0.617819 0.82245,0.938643 0.68802,1.381398 -5.582785,4.576322 -9.157134,6.570081 -9.997908,13.385698 -0.05108,0.198146 -0.46952,0.535131 -0.59154,0.391581 -5.588887,-10.390219 -7.807391,-22.092772 2.76826,-27.051307 z",
    x: 88.85520935058594,
    y: 75.46710205078125
  },
  {
    d:
      "m 110.92652,61.924143 c -0.48398,4.252962 -1.87198,7.18702 -7.13317,11.89263 -0.69076,0.617819 -0.82245,0.938643 -0.68802,1.381398 5.58279,4.576322 9.15713,6.570081 9.99791,13.385698 0.0511,0.198146 0.46952,0.535131 0.59154,0.391581 5.58889,-10.390219 7.80739,-22.092772 -2.76826,-27.051307 z",
    x: 110.71622467041016,
    y: 75.46709442138672
  },
  {
    d:
      "m 61.780882,80.523603 c 1.333748,-1.256672 4.516087,-11.372301 3.267892,-11.154076 -1.002172,0.175218 -4.970027,5.269062 -4.890096,7.833122 0.01962,0.629456 1.622204,3.320954 1.622204,3.320954",
    x: 62.74544143676758,
    y: 74.94482803344727
  },
  {
    d:
      "m 137.79055,80.523599 c -1.33375,-1.256672 -4.51609,-11.372301 -3.26789,-11.154076 1.00217,0.175218 4.97003,5.269062 4.89009,7.833122 -0.0196,0.629456 -1.6222,3.320954 -1.6222,3.320954",
    x: 136.82599639892578,
    y: 74.94482040405273
  },
  {
    d:
      "m 73.027975,72.340164 c -1.063602,-1.25647 3.518174,-8.37579 5.027043,-8.647967 1.295745,-0.319275 2.785286,3.312893 2.675653,4.251954 -0.848138,7.264754 -0.773384,9.141008 -0.849057,10.671698 -0.04443,0.05219 -0.156815,0.179619 -0.197568,0.131437 -2.1735,-2.569762 -5.307031,-4.902187 -6.655884,-6.407122 z",
    x: 76.80292892456055,
    y: 71.21517944335938
  },
  {
    d:
      "m 126.54346,72.34016 c 1.0636,-1.25647 -3.51818,-8.37579 -5.02705,-8.647967 -1.29574,-0.319275 -2.78528,3.312893 -2.67565,4.251954 0.84814,7.264754 0.77339,9.141008 0.84906,10.671698 0.0444,0.05219 0.15681,0.179619 0.19757,0.131437 2.1735,-2.569762 5.30703,-4.902187 6.65588,-6.407122 z",
    x: 122.76850128173828,
    y: 71.21517181396484
  },
  {
    d:
      "m 75.756056,60.832897 c 1.025545,1.40458 -3.264448,9.333888 -4.369058,10.138974 -2.367417,1.618227 -3.599224,1.659307 -4.511616,2.730925 -0.04443,0.05219 -0.179904,0.19202 -0.197568,0.131437 -1.38987,-4.165385 3.401121,-11.157012 9.078242,-13.001336 z",
    x: 71.1742057800293,
    y: 67.34103584289551
  },
  {
    d:
      "m 123.81538,60.832893 c -1.02555,1.40458 3.26445,9.333888 4.36905,10.138974 2.36742,1.618227 3.59923,1.659307 4.51162,2.730925 0.0444,0.05219 0.1799,0.19202 0.19757,0.131437 1.38987,-4.165385 -3.40112,-11.157012 -9.07824,-13.001336 z",
    x: 128.39723205566406,
    y: 67.34103393554688
  },
  {
    d:
      "m 99.963483,56.413469 c 2.714787,1.856045 7.937497,4.59786 7.698367,7.652531 -0.7345,3.899999 -5.97181,7.591874 -8.493717,10.719266 -2.02474,-2.90127 -7.431092,-7.831604 -7.32542,-10.760174 0.127116,-3.522881 5.11379,-5.779682 8.12077,-7.611623 z",
    x: 99.75548934936523,
    y: 65.59936904907227
  },
  {
    d:
      "m 79.336403,60.797313 c -1.063602,-1.25647 4.58737,-7.20677 6.096239,-7.478947 1.025545,1.40458 2.905475,6.672285 1.800865,7.477371 -2.367417,1.618227 -4.000121,3.155991 -4.912513,4.227609 -0.04443,0.05219 -0.156815,0.179619 -0.197568,0.131437 -2.1735,-2.569763 -1.437983,-2.852535 -2.786836,-4.35747 z",
    x: 83.38715362548828,
    y: 59.24191665649414
  },
  {
    d:
      "m 120.23503,60.797309 c 1.0636,-1.25647 -4.58737,-7.20677 -6.09624,-7.478947 -1.02554,1.40458 -2.90547,6.672285 -1.80086,7.477371 2.36741,1.618227 4.00012,3.155991 4.91251,4.227609 0.0444,0.05219 0.15681,0.179619 0.19757,0.131437 2.1735,-2.569763 1.43798,-2.852535 2.78683,-4.35747 z",
    x: 116.18428421020508,
    y: 59.24191474914551
  },
  {
    d:
      "m 77.547215,62.425643 c 0.243058,0.274621 0.598367,0.138518 0.611084,-0.215041 0.03403,-0.94598 0.217309,-2.27128 0.813227,-3.453502 1.311164,-2.601179 3.823292,-5.031783 3.528654,-4.686354 -1.002172,0.175218 -6.385077,2.69491 -6.305156,5.25897 0.0052,0.165271 0.03622,0.541156 0.174511,0.881813 0.388411,0.956781 1.17768,2.214114 1.17768,2.214114",
    x: 79.35907363891602,
    y: 58.30472373962402
  },
  {
    d:
      "m 122.02422,62.425639 c -0.24306,0.274621 -0.59837,0.138518 -0.61109,-0.215041 -0.034,-0.94598 -0.2173,-2.27128 -0.81322,-3.453502 -1.31117,-2.601179 -3.82329,-5.031783 -3.52866,-4.686354 1.00218,0.175218 6.38508,2.69491 6.30516,5.25897 -0.005,0.165271 -0.0362,0.541156 -0.17451,0.881813 -0.38841,0.956781 -1.17768,2.214114 -1.17768,2.214114",
    x: 120.21236419677734,
    y: 58.30471992492676
  },
  {
    d:
      "m 92.952558,50.565205 c 1.17507,1.760001 6.131055,3.596303 5.255585,5.363121 -4.640972,0.110497 -6.661978,4.242839 -7.536715,6.218197 -4.213396,-6.407793 -3.920054,-10.539002 2.28113,-11.581318 z",
    x: 93.07887649536133,
    y: 56.35586357116699
  },
  {
    d:
      "m 106.61888,50.565201 c -1.17508,1.760001 -6.13106,3.596303 -5.25559,5.363121 4.64097,0.110497 6.66198,4.242839 7.53672,6.218197 4.21339,-6.407793 3.92005,-10.539002 -2.28113,-11.581318 z",
    x: 106.49256134033203,
    y: 56.35585975646973
  },
  {
    d:
      "m 86.486389,53.417419 c 0,0 0.522727,0.01504 0.702406,-0.263167 2.230458,-3.071266 5.450951,-3.493236 5.589772,-4.015559 0.146215,-0.550149 -2.569142,-1.221921 -4.916637,1.705793 -1.393275,1.716216 -1.528018,2.686688 -1.375541,2.572933 z",
    x: 89.61069107055664,
    y: 51.04161834716797
  },
  {
    d:
      "m 113.08504,53.417415 c 0,0 -0.52272,0.01504 -0.7024,-0.263167 -2.23046,-3.071266 -5.45095,-3.493236 -5.58977,-4.015559 -0.14622,-0.550149 2.56914,-1.221921 4.91663,1.705793 1.39328,1.716216 1.52802,2.686688 1.37554,2.572933 z",
    x: 109.96075439453125,
    y: 51.0416145324707
  },
  {
    d:
      "m 105.05168,49.344843 c -1.26366,3.238552 -2.65798,2.507211 -5.179897,5.634602 -1.35022,-1.85884 -4.62297,-2.772307 -4.954441,-5.683976 5.263848,-4.882302 8.764208,-0.870826 10.134338,0.04937 z",
    x: 99.98451232910156,
    y: 50.950294494628906
  }
];
