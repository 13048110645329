import React from "react";
import Grid from "@material-ui/core/Grid";
import NhogsInteractiveLogo from "../containers/NhogsInteractiveLogo";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

function AboutNhogs() {
  const useStyles = makeStyles(theme => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5)
    },

    gridContent : {
      flex: '1 1 auto !important'
    }
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} className={classes.gridContent}/>

        <Grid item xs={12} className={classes.gridContent}>
          <NhogsInteractiveLogo />
        </Grid>

        <Grid item xs={10} className={classes.gridContent}>
          <Typography style={{ textAlign: "center" }} variant="h5">
            NHOGS Interactive is a software development team created in 2017
          </Typography>
          <Typography style={{ textAlign: "center" }} variant="h5">
            Focused on various technologies, from data analysis and web
            visualization tools to gaming and virtual reality.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent} />
      </Grid>
    </div>
  );
}

AboutNhogs.propTypes = {};
export default AboutNhogs;
