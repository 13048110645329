import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createTypography from "@material-ui/core/styles/createTypography";
import "./App.css";
import themeColors from "./themeColors";

export const theme = (() => {
  const palette = {
    ...themeColors
  };

  const typography = createTypography(palette, {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;'
  });

  return createMuiTheme({
    palette,
    typography
  });
})();
