import AboutPopoto from "../../containers/project-pages/AboutPopoto";
import Divider from "@material-ui/core/Divider";
import AboutObservable from "../../containers/project-pages/AboutObservable";
import AboutVR from "../../containers/project-pages/AboutVR";
import About3D from "../../containers/project-pages/About3D";
import React from "react";

function ProjectPages() {
  return (
    <div>
      <AboutPopoto />
      <Divider />
      <AboutObservable />
      <Divider />
      <AboutVR />
      <Divider />
      <About3D />
    </div>
  );
}

export default ProjectPages;
