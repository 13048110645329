import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";

function AboutVR() {
  const useStyles = makeStyles(theme => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5)
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold"
    },

    gridContent : {
      flex: '1 1 auto !important'
    }
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent} />

        <Grid item xs={12} className={classes.gridContent}>
          <Typography
            style={{ textAlign: "center", whiteSpace: "pre-line" }}
            variant="h4"
          >
            Video games & VR
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <video
            autoPlay
            controls
            loop
            muted
            style={{
              width: "100%",
              height: "auto",
              margin: "auto"
            }}
            src="https://i.imgur.com/UNwmvEM.mp4"
          >
            Your browser does not support HTML5 video. check out the gif{" "}
            <a
              href="https://i.imgur.com/UNwmvEM.gif"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </video>
        </Grid>
        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            Nhogs is currently in research and development around video games
            and VR.
          </Typography>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            <a
              className={classes.link}
              href="https://www.linkedin.com/in/steeve-ciminera-b3b426153/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Steeve
            </a>
            , a young game developer, is working on asset and much more. Take a
            look at this{" "}
            <a
              className={classes.link}
              href="https://imgur.com/a/oayvsUN"
              target="_blank"
              rel="noopener noreferrer"
            >
              Imgur album
            </a>{" "}
            containing some of his works.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

AboutVR.propTypes = {};
export default AboutVR;
