export const D3SCALES = [
  "interpolateRdYlGn",
  "interpolateRdYlBu",
  "interpolateBrBG",
  "interpolatePRGn",
  "interpolatePiYG",
  "interpolatePuOr",
  "interpolateRdBu",
  "interpolateRdGy",
  "interpolateSpectral",
  "interpolateBuGn",
  "interpolateBuPu",
  "interpolateGnBu",
  "interpolateOrRd",
  "interpolatePuBuGn",
  "interpolatePuBu",
  "interpolatePuRd",
  "interpolateRdPu",
  "interpolateYlGnBu",
  "interpolateYlGn",
  "interpolateYlOrBr",
  "interpolateYlOrRd",
  "interpolateBlues",
  "interpolateGreens",
  "interpolateGreys",
  "interpolatePurples",
  "interpolateReds",
  "interpolateOranges",
  "interpolateCubehelixDefault",
  "interpolateRainbow",
  "interpolateSinebow",
  "interpolateViridis",
  "interpolateMagma",
  "interpolateInferno",
  "interpolatePlasma"
];
