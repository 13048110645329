import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import animatedNhogsLogo from "../d3/animatedNhogsLogo";

function NhogsInteractiveLogo() {
  const container = useRef(null);

  useEffect(() => {
    const node = d3.select(container.current);
    animatedNhogsLogo.render(
      node,
      d3.interpolateOranges,
      d3.easeBackOut,
      0,
      -15
    );
  }, [container]);

  return (
    <div>
      <svg ref={container} width="200" height="250" style={{userSelect: "none"}}/>
    </div>
  );
}

NhogsInteractiveLogo.propTypes = {};
export default NhogsInteractiveLogo;
