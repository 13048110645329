import * as parseSVG from "parse-svg-path";

export function pathToZdogPath(path, z = 0) {
  let pathParsed = parseSVG(path);
  let lastPos = [0, 0];
  let Zpath = [];
  let lastCPos = undefined;
  pathParsed.forEach(d => {
    if (d[0] === "L") {
      Zpath.push({ line: { x: d[1], y: d[2], z } });
      lastPos = [d[1], d[2]];
    } else if (d[0] === "V") {
      //Zpath.push({ move: { x: lastPos[0], y: lastPos[1], z } });
      Zpath.push({ line: { x: lastPos[0], y: d[1], z } });
      lastPos = [lastPos[0], d[1]];
    } else if (d[0] === "H") {
      Zpath.push({ line: { x: d[1], y: lastPos[1], z } });
      lastPos = [d[1], lastPos[1]];
    } else if (d[0] === "M") {
      Zpath.push({ move: { x: d[1], y: d[2], z } });
      lastPos = [d[1], d[2]];
    } else if (d[0] === "C") {
      Zpath.push({
        bezier: [
          { x: d[1], y: d[2], z },
          { x: d[3], y: d[4], z },
          { x: d[5], y: d[6], z }
        ]
      });
      lastCPos = [d[3], d[4]];
      lastPos = [d[5], d[6]];
    } else if (d[0] === "S") {
      let x = lastCPos ? lastPos[0] - lastCPos[0] : 0;
      let y = lastCPos ? lastPos[1] - lastCPos[1] : 0;
      Zpath.push({
        bezier: [
          {
            x: lastPos[0] + x,
            y: lastPos[1] + y,
            z
          },
          { x: d[1], y: d[2], z },
          { x: d[3], y: d[4], z }
        ]
      });
      lastCPos = [d[1], d[2]];
      lastPos = [d[3], d[4]];
    }

    if (d[0] !== "C" && d[0] !== "S") lastCPos = undefined;
  });
  return Zpath;
}
