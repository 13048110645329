import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import WhiteNhogsEgg from "../icons/eggs/whiteAlpha.svg"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import AppRoutes from "../containers/AppRoutes"

function TopAppBar({ goToPath }) {

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },

    menuButton: {
      marginRight: theme.spacing(2)
    },

    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        flexGrow: 1
      },
      cursor: "pointer"
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="header">
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={() => {
                goToPath("/");
              }}
            >
              <img src={WhiteNhogsEgg} alt="" width="50" height="50" />
            </IconButton>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              onClick={() => {
                goToPath("/");
              }}
            >
              NHOGS Interactive
            </Typography>

            <div>
              <Button
                color="secondary"
                edge="end"
                onClick={() => {
                  goToPath("/projects");
                }}
              >
                projects
              </Button>
              {/*<Button color='secondary' edge="end"
              onClick={() => {
                goToPath("/contact")
              }}>
              Contact
            </Button>*/}
            </div>
          </Toolbar>
        </AppBar>
      </div>

      <div className='content'>
        <AppRoutes/>
      </div>
    </div>
  );
}

TopAppBar.propTypes = {};
export default TopAppBar;
