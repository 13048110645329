import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import HomePage from "../components/HomePage";

export default withRouter(
  connect(
    (state, props) => ({}),
    (dispatch, props) => ({
      initialActions() {},
      goToPath(path) {
        dispatch(push(path));
      }
    })
  )(HomePage)
);
