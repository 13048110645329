import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import AppRoutes from "../components/AppRoutes";

export default withRouter(
  connect(
    (state, props) => ({}),
    (dispatch, props) => ({
      initialActions() {},
      goToPath(path) {
        dispatch(push(path));
      }
    })
  )(AppRoutes)
);
