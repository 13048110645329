import * as d3 from "d3";
import { nhogsLogo } from "./nhogsLogoData";
import { D3SCALES } from "./d3ColorScales";

const animatedNhogsLogo = {};

animatedNhogsLogo.render = (svg, colorScaleType, transType, x, y, size) => {
  let scalesData = nhogsLogo.map(d => Object.create(d));

  let scalesContainer = svg
    .selectAll("g")
    .data(["g"])
    .enter()
    .append("g")
    .attr("transform", `translate(${x},${y})`);

  let scales = scalesContainer
    .selectAll(".scale")
    .data(scalesData, d => `${d.x}|${d.y}`);

  scales
    .enter()
    .append("g")
    .attr("class", "scale")
    .attr("fill", "white")
    .attr("stroke", "black")
    .style("cursor", "pointer")
    .append("path")
    .attr("d", d => d.d)
    .attr("transform", d => `translate(${0},${0})`)
    .merge(scales)
    .on("mouseover", bouingStart)
    .on("mouseout", bouingEnd)
    .on("click", logoClicked);

  function bouingStart(z) {
    let mouseCoor = d3.mouse(svg.node());

    svg
      .selectAll(".scale")
      .transition()
      .duration(d => 500 + 10 * delayFromPoint(d, z))
      .ease(transType)
      .attr("transform", d => {
        return `translate(${(mouseCoor[0] - d.x) / 6.5},${(mouseCoor[1] - d.y) /
          6.5})`;
      })
      .attr("fill", (d, i, u) => {
        return colorScaleType(delayFromPoint(d, z) / 170);
      });
  }

  function bouingEnd(z) {
    svg
      .selectAll(".scale")
      .transition()
      .ease(d3.easeElasticOut)
      .duration(d => 200 + 20 * delayFromPoint(z, d))
      .attr("transform", d => `translate(${0},${0})`);
  }

  function logoClicked(z) {
    colorScaleType =
      d3[D3SCALES[Math.floor(Math.random() * Math.floor(D3SCALES.length - 1))]];

    svg
      .selectAll(".scale")
      .transition()
      .ease(d3.easeElasticOut)
      .duration(d => 200 + 20 * delayFromPoint(z, d))
      .attr("transform", d => `translate(${0},${0})`)
      .attr("fill", (d, i, u) => {
        return colorScaleType(delayFromPoint(d, z) / 170);
      });
  }

  function delayFromPoint(current, origin) {
    return Math.sqrt(
      Math.pow(current.x - origin.x, 2) + Math.pow(current.y - origin.y, 2)
    );
  }
};

export default animatedNhogsLogo;
