import React, { useEffect, useRef, useState } from "react";
import { getLogo } from "../helpers/popoto3dLogoHelpers";
import useInterval from "../hooks/useInterval";
import * as d3 from "d3";

function BreathingEgg() {
  let [logo, setLogo] = useState(null);
  let [prevMousePos, setPrevMousePos] = useState(null);
  let [timestamp, setTimestamp] = useState(null);
  let [force, setForce] = useState({ x: -0.05 });

  const canvasContainer = useRef(null);

  const mouseMove = e => {
    const mousePos = {
      x: e.pageX,
      y: e.pageY
    };

    // if we're missing some data, we retrieve it and break
    let returnTest = false;

    if (!prevMousePos) {
      setPrevMousePos({ ...mousePos });
      returnTest = true;
    }

    if (!timestamp) {
      timestamp = Date.now();
      returnTest = true;
    }

    if (returnTest) return;

    //compute the y speed by getting distance delta and time delta
    const dy = mousePos.y - prevMousePos.y;

    let now = Date.now();
    let dt = now - timestamp;

    let speedY = dy / dt;

    if (logo) {
      //set the force according to mouse speed
      setForce({ x: Math.min(force.x - speedY / 10, 0.4) });
      logo.main.updateRenderGraph();
    }

    setPrevMousePos({ ...mousePos });
    setTimestamp(now);
  };

  //Physics update interval
  useInterval(() => {
    if (logo) {
      //Custom magnet that stop force decrease
      //until start rotation is achieved again
      if (Math.abs(force.x) > 0 && Math.abs(force.x) < 0.025) {
        const magnetPos = Math.abs(logo.zAnchor.rotate.x) % (2 * Math.PI);

        //if it's slow and close enough we stop everything
        if (magnetPos < 0.1) {
          setForce({ x: 0 });
          logo.zAnchor.rotate.x = 0;
          logo.main.updateRenderGraph();
        } else {
          //if it's too far we don't decrease force
          logo.zAnchor.rotate.x = logo.zAnchor.rotate.x + force.x;
          logo.main.updateRenderGraph();
        }
      } else {
        //Force update is linear
        //but allows a fast decrease when high and a slow one when low
        setForce({ x: force.x * 0.95 });
        logo.zAnchor.rotate.x = logo.zAnchor.rotate.x + force.x;
        logo.main.updateRenderGraph();
      }

      //The faster the hotter, so we put some red on the ring and branches
      logo.ring.color = d3.interpolateRgb("black", "red", "yellow")(
        Math.abs(force.x) * 2
      );
      logo.branches.forEach(
        b =>
          (b.color = d3.interpolateRgb("black", "red")(Math.abs(force.x) * 2))
      );
    }
    //very small update time to have at least 60 fps
    //increase to have better performance
  }, 16);

  useEffect(() => {
    if (!logo) {
      setLogo(getLogo(canvasContainer.current, false));
    } else {
      logo.main.updateRenderGraph();
    }
  }, [logo]);

  return (
    <div style={{ userSelect: "none" }}>
      <svg
        ref={canvasContainer}
        width="500"
        height="200"
        viewBox="0 0 500 200"
        onMouseMove={mouseMove}
        onMouseLeave={() => setPrevMousePos(null)}
        onClick={() => {
          if (logo) {
            setForce({ x: 1 });
          }
        }}
        style={{ cursor: "grabbing", width: "100%" }}
      />
    </div>
  );
}

BreathingEgg.propTypes = {};
export default BreathingEgg;
